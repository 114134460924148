<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns">
          <div class="column">
            <div class="is-flex is-align-items-end">
              <div class="control mr-2">
                <p class="title">Shop Targets</p>
              </div>
              <div v-if="showSavingStatus && !hasError && !toastNotificationEnabled"
                class="control is-flex is-align-items-center">
                <span v-if="saving"
                  class="button is-loading is-white"
                  style="height: 27px;" />
                <span v-else
                  class="text has-text-success">
                  <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
                </span>
                <span class="is-size-6"
                  :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="is-flex is-justify-content-right">
              <button class="button is-primary"
                @click="createFiscalYear"
                :disabled="isNew || (!isNew && entity && entity.length !== 0)">Create FY{{ nextFiscalYear }}</button>
            </div>
          </div>
        </div>
        <div class="columns pb-3">
          <div class="column is-narrow">
            <div class="field is-horizontal">
              <div class="field">
                <label class="label">Fiscal Start Month</label>
                <div class="control">
                  <div class="select">
                    <select v-model="innerCompanySetting.fiscalStart"
                      :disabled="isNew || fiscalYearList.length !== 0">
                      <option value="null"
                        disabled>MM</option>
                      <option v-for="(month, key) in months"
                        :value="key"
                        :key="key">{{ month }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="field is-horizontal">
              <div class="field">
                <label class="label">Fiscal Duration</label>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="innerCompanySetting.fiscalDuration"
                        placeholder="Fiscal Duration"
                        :min="12"
                        :precision="0"
                        :disabled="isNew || fiscalYearList.length !== 0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow pr-5 mr-5">
            <div class="field is-horizontal">
              <div class="field">
                <label class="label">Fiscal Year</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="selectedFiscalYear"
                      @change="getMonthlyEntity($event.target.value)"
                      :disabled="isNew">
                      <option :value="null"
                        disabled>Select</option>
                      <option v-for="(val, key) in fiscalYearList"
                        :key="key"
                        :value="val">{{ val }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <div class="pretty p-default p-round p-bigger p-smooth is-flex is-align-items-top mb-3">
              <input v-model="targetMode"
                id="dailyMode"
                type="radio"
                name="rd-targetMode"
                :value="'Daily'"
                :disabled="!isNew && selectedFiscalYear === null"
                @change="changeTargetMode">
              <div class="state p-primary-o">
                <label class="label has-text-weight-bold">Daily Target</label>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="dailyTarget"
                    placeholder="Daily Target"
                    :min="0"
                    :precision="0"
                    :disabled="(!isNew && selectedFiscalYear === null) || targetMode === 'Yearly'"
                    :symbol="$company.currency.symbol"
                    @input.native="updateDailyTarget(dailyTarget)" />
                </div>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <div class="pretty p-default p-round p-bigger p-smooth is-flex is-align-items-top mb-3">
              <input v-model="targetMode"
                id="yearlyMode"
                type="radio"
                name="rd-targetMode"
                :value="'Yearly'"
                :disabled="!isNew && selectedFiscalYear === null"
                @change="changeTargetMode">
              <div class="state p-primary-o">
                <label style="font-size: 14px; font-weight: bold;">Yearly Target</label>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="yearlyTarget"
                    placeholder="Yearly Target"
                    :min="0"
                    :precision="0"
                    :disabled="(!isNew && selectedFiscalYear === null) || targetMode === 'Daily'"
                    :symbol="$company.currency.symbol"
                    @input.native="updateYearlyTarget()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow">
            <div class="field is-horizontal">
              <div class="field">
                <label class="label">Initial Monthly Working Days</label>
                <div class="field-body">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="avgWorkingDays"
                      placeholder="Monthly Working Days"
                      :min="0"
                      :precision="0"
                      :disabled="!isNew && selectedFiscalYear === null"
                      @input.native="updateAvgWorkingDays(avgWorkingDays)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div>
            <button class="button is-danger mr-5"
              @click="deleteFiscalYear"
              :disabled="!showDelete">Delete</button>
            <button class="button is-primary"
              @click="cancel"
              :disabled="!showCancel">Cancel</button>
          </div>
        </div>
        <div v-if="entity && entity.length !== 0">
          <div class="is-divider" />
          <div v-show="entity && entity.length !== 0"
            class="title is-5">FY {{ entity[0].fiscalYearName }}</div>
          <table class="table is-striped is-narrow">
            <thead>
              <tr>
                <th class="no-border">&nbsp;</th>
                <th class="has-text-centered no-border">Working Days</th>
                <th class="has-text-centered no-border">Monthly Target</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in entity"
                :key="index">
                <td class="has-text-weight-bold has-vertical-middle column-min-width no-border">
                  {{ getMonthAbbr(item.month) }} {{ item.year }}
                </td>
                <td class="has-vertical-middle has-text-right no-border">
                  <vue-numeric class="input has-text-right"
                    v-model="item.workingDays"
                    placeholder="Working Days"
                    :min="0"
                    :precision="0"
                    @input.native="updateMonthWorkingDays(item)" />
                </td>
                <td class="has-vertical-middle has-text-right money-input no-border">
                  <vue-numeric class="input has-text-right"
                    v-model="item.monthlyTarget"
                    placeholder="Monthly Target"
                    :min="0"
                    :precision="0"
                    :symbol="$company.currency.symbol"
                    :disabled="disableMonthlyTarget"
                    @input.native="updateMonthMonthlyTarget(item.monthlyTarget)" />
                </td>
              </tr>
            </tbody>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </table>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteFiscalYearSetting"
      @cancel="cancelModel"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete FY{{ fiscalYear }}</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">FY{{ fiscalYear }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { DeepDiff } from 'deep-diff'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { getMonthAbbr } from '@/components/utils/CalendarFunctions'
import { CompanySettingService } from '@/services'
import { getFiscalYearName, getFiscalYearDates, addMonthsToDate } from '@/components/utils/CalendarFunctions'
import { ConfirmModal } from '@/components/BulmaModal'
import { CompanyMonthlySettingModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import VueNumeric from '@/components/VueNumeric'

export default {
  name: 'CompanyMonthlySetting',
  components: {
    VueNumeric,
    ConfirmModal
  },
  mixins: [StoreMixin],
  data() {
    return {
      companySetting: {},
      innerCompanySetting: {},
      isConfirmModalActive: false,
      isUnsavedModalActive: false,
      selectedFiscalYear: null,
      avgWorkingDays: 0,
      yearlyTarget: 0,
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      },
      fiscalYearList: [],
      fiscalYear: null,
      nextFiscalYear: null,
      dailyTarget: 0,
      targetMode: 'Daily',
      entity: null,
      isNew: false
    }
  },
  computed: {
    fiscalStart() {
      return this.innerCompanySetting.fiscalStart
    },
    fiscalDuration() {
      return this.innerCompanySetting.fiscalDuration
    },
    showDelete() {
      const length = this.fiscalYearList.length
      if (length === 0) return false

      const index = this.fiscalYearList.indexOf(this.fiscalYear)
      return index === length - 1
    },
    showCancel() {
      return this.entity && this.entity.length !== 0
    },
    disableMonthlyTarget() {
      return this.targetMode === 'Daily'
    }
  },
  watch: {
    companySetting: {
      handler: function (val) {
        this.innerCompanySetting = _cloneDeep(val)
      },
      deep: true
    },
    entity: {
      handler: function (val) {
        if (val === null) this.yearlyTarget = 0
        this.updateMonthMonthlyTarget()
        this.saveSnapshot({ entity: _cloneDeep(val)})
        this.autoUpdateFiscalSetting(val)
      },
      deep: true
    },

    fiscalStart() {
      this.getNextFiscalYear()
    },

    fiscalDuration() {
      this.getNextFiscalYear()
    },

    fiscalYear(newVal) {
      if (!this.isNew && newVal === null) {
        this.reset()
      }

      if (this.isNew) {
        this.selectedFiscalYear = null
      } else {
        this.selectedFiscalYear = newVal
      }
    },
    isNew(newVal) {
      if (newVal) {
        this.reset()
      } else {
        this.selectedFiscalYear = this.fiscalYear
      }
    },
    selectedFiscalYear(newVal) {
      this.fiscalYear = newVal
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companySetting = await CompanySettingService.getEntity()
    await this.getFiscalYearList()
    await this.getCurrentFiscalYear()
    this.$hideSpinner()
  },
  methods: {
    disableAutoUpdate() {
      if (typeof this.autoUpdateFiscalSetting === 'function'
        && typeof this.autoUpdateFiscalSetting.cancel === 'function' ) {
          this.autoUpdateFiscalSetting.cancel()
        }
      this.autoUpdateFiscalSetting = () => {}
    },

    initializeAutoUpdate() {
      this.autoUpdateFiscalSetting = _debounce(async (value) => {
        this.snapshotDiff && await this.updateFiscalSetting(value)
      }, this.debounceDelay)
    },

    async updateFiscalSetting(fiscalSetting) {
      if (!this.snapshotDiff) return

      if (this.targetMode === 'Yearly') {
        this.entity.forEach((i) => (i.dailyTarget = null))
      }

      for (let item of fiscalSetting) {
        const [baseItem] = this.baseSnapshot.filter(i => i.companyMonthlySettingId == item.companyMonthlySettingId)
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      }

      await this.editStoreItem({
        serviceFunction: 'putMonthlySettingEntity',
        entity: fiscalSetting,
        params: this.entity[0].year
      })

      this.entity = _cloneDeep(await this.getStoreItem({
        serviceFunction: 'getCompanyMonthlySettingByFY', params: this.fiscalYear }))
      this.getNextFiscalYear()

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    },

    async updateCompanySetting() {
      const companySettingDiff = DeepDiff.diff(this.innerCompanySetting, this.companySetting)
      if (!companySettingDiff) return

      this.companySetting.deepDiff = JSON.stringify(companySettingDiff)
      this.companySetting = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanySetting', entity: this.innerCompanySetting
      }))
    },

    async createFiscalYear() {
      this.$showSpinner()
      if (this.fiscalYearList.length == 0) {
        await this.updateCompanySetting()
      }

      this.isNew = true
      let dateFrom = new Date()
      let dateTo = new Date()
      if (this.fiscalYearList.length !== 0) {
        const [lastMonth, lastYear] = await this.getLastFiscalYear()
        // javascript month is start with 0
        dateFrom = new Date(lastYear, lastMonth)
        dateTo = addMonthsToDate(dateFrom, this.fiscalDuration - 1)
      } else {
        ;[dateFrom, dateTo] = await getFiscalYearDates(this.fiscalStart, this.fiscalDuration, true)
      }
      this.fiscalYear = this.nextFiscalYear.toString()

      let newEntities = []
      for (let currentDate = new Date(dateFrom); currentDate <= dateTo; currentDate.setMonth(currentDate.getMonth() + 1)) {
        // Extract month and year from the current date
        const month = currentDate.toLocaleString('default', { month: 'numeric' })
        const year = currentDate.getFullYear()
        const model = new CompanyMonthlySettingModel(this.$userInfo.companyId, this.nextFiscalYear, year, month)
        newEntities.push(model)
      }

      this.entity = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'postMonthlySettingEntity',
        entity: newEntities
      }))

      this.fiscalYearList.push(this.fiscalYear)
      this.$hideSpinner()
    },

    async deleteFiscalYearSetting() {
      this.entity.forEach((item) => {
        const baseItem = _cloneDeep(item)
        item.isDeleted = true
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      })

      await this.editStoreItem({
          serviceFunction: 'putMonthlySettingEntity',
          entity: _cloneDeep(this.entity),
          params: this.entity[0].year
        })

      let index = this.fiscalYearList.indexOf(this.fiscalYear)
      if (index !== -1) {
        this.fiscalYearList.splice(index, 1)
      }
      this.entity = null
      this.targetMode = 'Daily'
      this.fiscalYear = null
      this.isConfirmModalActive = false
      this.getNextFiscalYear()

      this.isNew = false
    },

    async getMonthlyEntity(fiscalYear) {
      this.avgWorkingDays = 0
      this.fiscalYear = fiscalYear
      this.getFiscalSetting()
    },

    deleteFiscalYear() {
      this.isConfirmModalActive = true
    },

    cancel() {
      this.isNew = false
      this.entity = null
      this.targetMode = 'Daily'
      this.fiscalYear = null
      this.action = null
      this.dailyTarget = 0
    },

    cancelModel() {
      this.closeModal()
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        this.toRoute = null
      }
    },

    closeModal() {
      if (!this.isSkipSave && !this.isSaveContinue && this.action !== 'routeLeave') {
        this.fiscalYear = this.entity ? this.entity[0].fiscalYearName.toString() : null
      }
      this.isConfirmModalActive = false
      this.isUnsavedModalActive = false
      this.isSaveContinue = false
      this.isSkipSave = false
    },

    reset() {
      this.selectedFiscalYear = null
      this.avgWorkingDays = 0
      this.yearlyTarget = 0
      this.dailyTarget = 0
    },

    changeTargetMode() {
      if (this.targetMode === 'Daily') {
        const totalWorkingDays = this.entity.reduce((sum, month) => {
          sum += month.workingDays || 0
          return sum
        }, 0)

        if (totalWorkingDays !== 0) {
          const dailyTarget = this.yearlyTarget / totalWorkingDays
          this.dailyTarget = dailyTarget
          this.entity.forEach(function (month) {
            month.dailyTarget = dailyTarget
          })
        }
      }
    },

    updateDailyTarget(dailyTarget) {
      this.entity.forEach(function (month) {
        month.dailyTarget = dailyTarget
        month.monthlyTarget = month.workingDays * dailyTarget
      })
      this.dailyTarget = dailyTarget
    },

    updateYearlyTarget() {
      const totalWorkingDays = this.entity.reduce((sum, month) => {
        sum += month.workingDays || 0
        return sum
      }, 0)

      if (totalWorkingDays !== 0) {
        const dailyTarget = roundAwayFromZero(this.yearlyTarget / totalWorkingDays, 0)
        this.dailyTarget = 0
        this.entity.forEach(function (month) {
          month.dailyTarget = null
          month.monthlyTarget = month.workingDays * dailyTarget
        })
      }
    },

    updateAvgWorkingDays(avgWorkingDays) {
      if (this.targetMode === 'Daily') {
        this.entity.forEach((i) => {
          i.workingDays = avgWorkingDays
          i.monthlyTarget = avgWorkingDays * this.dailyTarget
        })
      } else {
        this.entity.forEach((i) => {
          i.workingDays = avgWorkingDays
        })
        this.updateYearlyTarget()
      }
    },

    updateMonthWorkingDays(item) {
      if (this.targetMode === 'Daily') {
        item.monthlyTarget = item.workingDays * this.dailyTarget
      }
    },

    updateMonthMonthlyTarget() {
      if (this.entity) {
        this.yearlyTarget = this.entity.reduce((sum, month) => {
          sum += month.monthlyTarget || 0
          return sum
        }, 0)
      }
    },

    getMonthAbbr(number) {
      return getMonthAbbr(number)
    },

    async getFiscalYearList() {
      this.fiscalYearList = await CompanySettingService.getFiscalYearList()
      this.getNextFiscalYear()
    },

    async getNextFiscalYear() {
      if (this.fiscalYearList.length !== 0) {
        const [lastMonth, lastYear] = await this.getLastFiscalYear()
        const lastFY = new Date(lastYear, lastMonth-1) // zero indexed for month
        const fiscalDateStart = addMonthsToDate(lastFY, 1)
        this.nextFiscalYear = getFiscalYearName(fiscalDateStart, this.fiscalDuration)
      } else {
        // get fiscal date start based on current date
        const [fiscalDateStart] = await getFiscalYearDates(this.fiscalStart, this.fiscalDuration, true)
        this.nextFiscalYear = getFiscalYearName(fiscalDateStart, this.fiscalDuration)
      }
    },

    async getCurrentFiscalYear() {
      const currentMonth = new Date().getMonth() + 1 // JavaScript months are zero-indexed
      const currentYear = new Date().getFullYear()
      this.fiscalYear = await CompanySettingService.checkExists(currentYear, currentMonth)
      if (this.fiscalYear !== null)
        await this.getFiscalSetting()
    },

    async getLastFiscalYear() {
        const lastFY = this.fiscalYearList[this.fiscalYearList.length - 1]
        const lastFYEntity = _cloneDeep(await this.getStoreItem({
          serviceFunction: 'getCompanyMonthlySettingByFY', params: lastFY}))
        const lastRecord = lastFYEntity[lastFYEntity.length - 1]
        const lastYear = lastRecord.year
        const lastMonth = lastRecord.month

        return [lastMonth, lastYear]
    },

    async getFiscalSetting() {
      this.$showSpinner('Loading...')
      this.entity = _cloneDeep(await this.getStoreItem({
        serviceFunction: 'getCompanyMonthlySettingByFY', params: this.fiscalYear }))

      if (this.entity && this.entity.length > 0) {
        if (this.entity[0].dailyTarget === null) {
          this.targetMode = 'Yearly'
          this.dailyTarget = 0
        } else {
          this.targetMode = 'Daily'
          this.dailyTarget = this.entity[0].dailyTarget
        }
      }
      this.isNew = false
      this.$hideSpinner()
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateFiscalSetting(this.entity)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
.no-border {
  border: none;
}
.column-min-width {
  min-width: 8em;
}
</style>
